import { Autorenew } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  spinner: {
    animation: "$spin infinite 2s linear",
  },
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}));

export const Spinner = () => {
  const classes = useStyles();

  return <Autorenew data-testid="spinner" className={classes.spinner} />;
};
