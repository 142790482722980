import useSWR from "swr";
import { ApiError } from "types/ApiErrors";

import { ClientTestInstance } from "types/Test";

export const useAvailableTests = (
  client_id: number | undefined,
  is_complete: boolean
) => {
  const url =
    typeof client_id === "number"
      ? `test/response?client_id=${client_id}&is_complete=${
          is_complete ? "true" : "false"
        }`
      : null;
  const { data, error } = useSWR<ClientTestInstance[], ApiError>(url);

  return {
    tests: data,
    loading: !data && !error,
  };
};
