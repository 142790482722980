import { Box, Typography } from "@mui/material";
import React from "react";

import { DashboardLayout } from "components/dashboard/DashboardLayout";
import { Spinner } from "components/loaders/Spinner";

import { ROUTES } from "constants/routes";
import { useAuth } from "hooks/useAuth";
import { useAvailableTests } from "hooks/useAvailableTests";
import { useSlugRouter } from "hooks/useSlugRouter";
import { ClientPermissions } from "utils/database/get-permissions";

import { MenuScope } from "types/Tables";

const hasMenu = (menu: MenuScope, permissions: ClientPermissions) =>
  permissions.menus.some((m) => m.menu_id === menu);

const Home: React.FC = () => {
  const router = useSlugRouter();
  const { permissions } = useAuth();
  const { client, isBaseStudent, isAuthenticated, isInitialized } = useAuth();
  const { tests } = useAvailableTests(client?.client_id, false);
  React.useEffect(() => {
    let dashboard = "";

    if (hasMenu("admin_dashboard", permissions)) {
      dashboard = ROUTES.DASHBOARD_ADMIN;
    } else if (hasMenu("user_dashboard", permissions)) {
      dashboard = ROUTES.DASHBOARD_USER;
    } else if (isBaseStudent) {
      if (client && tests) {
        if (tests.length === 0) {
          dashboard = ROUTES.TESTS_NO_TEST;
        } else {
          dashboard = `${ROUTES.TEST}?test_id=${tests[0].fawbstest_id}&response_id=${tests[0].youth_results_id}`;
        }
      }
    }

    if (dashboard) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(dashboard);
    } else if (!isAuthenticated && isInitialized) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(ROUTES.AUTH_LOGIN);
    }
  }, [
    client,
    isBaseStudent,
    permissions,
    router,
    tests,
    isAuthenticated,
    isInitialized,
  ]);

  return (
    <DashboardLayout title="Redirecting">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
          <Typography variant="h6" sx={{ ml: 1, pt: 1 }}>
            Redirecting
          </Typography>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Home;
